/* Base styles */
.nav-container {
    width: 100%;
    height: 80px;
    padding: 0px 20px;
    background: white;
    box-shadow: 4px 4px 30px rgba(42, 67, 113, 0.15);
    border-bottom: 1px solid #6D6D6D;
    display: flex;
    z-index: 1000;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    position: fixed;
}

/* Header for logo and toggle */
.nav-header {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50%;
    height: auto;
}

/* Logo styling */
.nav-logo-img {
    height: 40px;
}

/* Navigation links container */
.nav-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.nav-links {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

/* Individual navigation items */
.nav-item {
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #1A1B1E;
    cursor: pointer;
    position: relative;
    padding: 10px 0;
}

.nav-item .nav-underline {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background: #00D444;
    transition: transform 0.3s ease;
}

.nav-item:hover .nav-underline {
    transform: translateX(-50%) scaleX(1);
}

/* Contact button */
.nav-contact {
    opacity: 0;
    position: fixed;
    top: 21px;
    right: 3%;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateY(20px);
    z-index: 1000;
}

.nav-contact.visible {
    opacity: 1;
    transform: translateY(0);
}

.nav-contact-btn {
    padding: 5px 20px;
    background: #00D444;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    align-items: center;
    width: 100%;
}

.nav-contact-btn:hover {
    opacity: 0.9;
}

/* Hamburger menu styling */
.nav-toggle-btn {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 20px;
    visibility: hidden;
}

.nav-toggle-btn .hamburger {
    width: 90%;
    height: 2px;
    background-color: #1A1B1E;
    border-radius: 2px;
    position: absolute;
    right: 0;
    transition: all 0.3s ease-in-out;
}

.nav-toggle-btn .hamburger:before,
.nav-toggle-btn .hamburger:after {
    content: '';
    width: 90%;
    height: 2px;
    background-color: #1A1B1E;
    border-radius: 2px;
    position: absolute;
    transition: all 0.3s ease-in-out;
}

.nav-toggle-btn .hamburger:before {
    top: -8px;
}

.nav-toggle-btn .hamburger:after {
    top: 8px;
}

.nav-toggle-btn .hamburger.open {
    background-color: transparent;
}

.nav-toggle-btn .hamburger.open:before {
    transform: rotate(45deg);
    top: 0;
}

.nav-toggle-btn .hamburger.open:after {
    transform: rotate(-45deg);
    top: 0;
}

/* Styles for screens 768px to 1024px */
@media (max-width: 1024px) {
    .nav-item {
        font-size: 12px; /* Increased size */
    }
    .nav-container {
        height: 60px;
    }
    .nav-contact {
        opacity: 0;
        position: fixed;
        top: 1.8%;
        right: 3%;
    }
    .nav-links {
        gap: 20px;
    }
    .nav-logo-img {
        height: 30px;
    }
    .nav-contact-btn {
        padding: 5px 20px;
        font-size: 12px;
        width: 100%;
    }
    .nav-contact-btn {
        font-size: 14px; /* Increased size */
    }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .nav-container {
        justify-content: space-between;
    }

    .nav-contact {
        top: 13px;
        right: 60px;
    }

    .nav-links-container {
        flex-direction: column;
        background: white;
        position: absolute;
        top: 60px;
        right: 0;
        z-index: 9;
        padding: 10px 0;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.3s ease, opacity 0.3s ease;
    }

    .nav-links-container.open {
        max-height: 300px;
        opacity: 1;
    }

    .nav-links {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        text-align: center;
    }

    .nav-logo {
        margin-left: 10%;
    }

    .nav-toggle-btn {
        visibility: visible;
    }
}

@media (max-width: 480px) {
    .nav-contact-btn {
        padding: 6px 10px;
        margin-right: 30px;
    }

    .nav-item {
        font-size: 16px;
    }
}
