@import url('https://fonts.googleapis.com/css2?family=Days+One&family=Goldman:wght@400;700&family=Gruppo&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap');

/* Main container */
.start-journey-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center the child */
    overflow: hidden;
}

/* Background gradient */
.background-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../asserts/images/Back-img.jpg'); /* Add your image path here */
    background-size: cover; /* Ensures the image covers the full container */
    background-position: center; /* Centers the image */
    z-index: 0;
    transform: all 0.4s ease-in-out ;
}

/* Content container */
.journey-content {
    width: 100%;
    max-width: 905px;
    position: relative;
    z-index: 1;
    margin-left: 10%;
    opacity: 0; /* Initially hide the content */
    animation: slideIn 1s ease-out forwards;
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%); /* Start from the left */
        opacity: 0;
    }
    100% {
        transform: translateX(0); /* End at normal position */
        opacity: 1;
    }
}

/* Button styling */
.journey-button {
    width: fit-content;
    padding: 10px 30px;
    background: white;
    display: flex;
    margin: 20px 0;
    border-radius: 5px;
    align-self: flex-start;
    transition: all 0.2s ease;
    cursor: pointer; 
    overflow: hidden;
    position: relative;
}

.journey-button:hover {
    background: #00D444; 
    color: white;
    padding-right: 58px; /* Change text color on hover */
}

/* Text inside the button */
.journey-button-text {
    text-align: center;
    color: black;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 30px;
    word-wrap: break-word;
    transition: color 0.2s ease; /* Smooth transition for text color */
}

.journey-button:hover .journey-button-text {
    color: white;

}

/* Bouncing arrow effect */
.journey-button::after {
    content: '➔'; /* Right arrow symbol */
    position: absolute;
    right: 9px;
    top: 2px;
    font-size: 30px;
    color: white;
    opacity: 0;
    transform: translateX(-10px);
    transition: transform 0.3s ease, opacity 0.3s ease;
    animation: bounce 1s infinite;
}

.journey-button:hover::after {
    opacity: 1;
    transform: translateX(0);
}

@keyframes bounce {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(2px);
    }
}

/* Expanding effect on click */
.journey-button.expand {
    width: 100%; /* Expand full width */
    background: #00D444;
    transition: width 0.5s ease;
}


/* Main text styling */
.main-text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.main-text {
    color: white;
    font-size: 42px;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    word-wrap: break-word;
}

/* Subtext styling */
.subtext-container {
    margin-bottom: 30px;
}

.subtext {
    color: white;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 40px;
    word-wrap: break-word;
}

/* Tagline styling */


.tagline {
    color: white;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

@media (max-width: 1024px) {
    .journey-content {
        margin-left: 4%;
        padding: 0 15px;
        max-width: 605px;
    }
    .main-text {
        font-size: 34px;
        font-weight: 700;
    }
    .tagline{
        font-size: 15px;
        margin-top: 30px;
    }

    .subtext {
        font-size: 15px; /* Smaller subtext */
        line-height: 30px; /* Adjust line height */
    }

    .journey-button {
        padding: 6px 16px;
    }

    .journey-button-text {
        font-size: 18px;
        font-weight: 600;
    }
    .start-journey-container {
        height: 80vh;
    }
    .main-text-container {
        margin-bottom: 5px;
    }

    .background-gradient {
        background-size: cover;
        width: 100%;
        height: 80vw;
    }
    .journey-button::after {
        right: 14px;
        top: 2px;
        font-size: 25px;
    }
    .start-journey-container {
        width: 100%;
        height: 80vw;
    }

}


@media (max-width: 768px) {
    .journey-content {
        margin-left: 2%;
        padding: 0 15px;
    }
    .start-journey-container {
        width: 100%;
    }

    .main-text {
        font-size: 24px;
        font-weight: 500;
    }
    .tagline{
        font-size: 10px;
        margin-top: 20px;
    }

    .subtext {
        font-size: 10px; /* Smaller subtext */
        line-height: 25px; /* Adjust line height */
    }

    .journey-button {
        padding: 6px 16px;
    }

    .journey-button-text {
        font-size: 9px; /* Smaller button text */
    }
    .start-journey-container {
        height: 80vh;
    }
    .main-text-container {
        margin-bottom: 5px;
    }

    .background-gradient {
        background-size: cover;
        width: 100%;
        height: 100%; /* Ensures the whole image is visible */
    }
    
}

/* Responsive styles for very small screens */
@media (max-width: 480px) {
    .main-text {
        font-size: 24px; /* Further reduce font size */
    }
    
    .tagline{
        font-size: 10px;
        margin-top: 50px;
    }

    .subtext {
        font-size: 10px;
        line-height: 20px;
    }

    .journey-button {
        padding: 6px 15px; /* Adjust button size */
    }

    .journey-button-text {
        font-size: 9px; /* Even smaller button text */
    }
    .start-journey-container {
        height: 50vh;
    }
    .background-gradient {
        background-size: cover;
        background-position: top;
    }

}