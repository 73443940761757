.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.footer-banner {
  position: relative;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Video stays behind the content */
}

.footer-banner-content {
  position: relative;
  z-index: 1; /* Content stays above the video */
  text-align: center;
  max-width: 90%;
}

.footer-banner-text p {
  color: rgb(40, 171, 46);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;
}

.footer-banner-title {
  color: white;
  font-size: 2.8rem;
  font-weight: 900;
  margin: 20px 0;
}

.footer-contact-button button {
  padding: 10px 36px;
  border-radius: 8px;
  background: white;
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.footer-contact-button button:hover {
  background: rgb(40, 171, 46);
  color: white;
}

.footer-links {
  width: 100%;
  padding: 5vh 10% 3vh 10%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-links-column {
  flex: 1;
  min-width: 200px; /* Ensures proper alignment for smaller screens */
  padding: 10px;
}

.footer-links-column h3 {
  color: white;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
}

.footer-links-column h3:hover {
  color: rgb(40, 171, 46);;
}

.footer-links-column ul {
  list-style: none;
  padding: 0;
}


.footer-links-column li {
  color: #ffffff;
  font-size: 0.9rem;
  line-height: 1.5rem;
}
.footer-links-column li:hover {
  color: rgb(40, 171, 46);;
}
.subscribe-column {
  flex: 2;
  text-align: left;
}

.footer-p {
  font-size: 0.9rem;
  color: #ffffff;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px 10%;
}

.footer-bottom-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-logo-img {
  height: 40px;
  margin-right: 20px;
}

.footer-bottom-right p {
  font-size: 14px;
  color: #ccc;
}

@media (max-width: 1024px) {
  .footer-banner-title {
    font-size: 2.5rem;
  }

  .footer-banner-text p {
    font-size: 1.3rem;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links-column {
    width: 100%;
    text-align: center;
  }

  .footer-links-column h3,
  .footer-links-column li {
    font-size: 1rem;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo-img {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .footer-banner-content {
    text-align: center;
    margin: 0 auto;
    padding: 10px;
  }
  
  .background-video {
    max-width: 100%;
    z-index: -1; /* Video stays behind the content */
  }

  .footer-banner-text p {
    font-size: 1rem;
    line-height: 1.4rem;
    color:#ffffff;
  }

  .footer-banner-title {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .footer-contact-button button {
    font-size: 1rem;
    padding: 8px 24px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-links-column {
    width: 100%;
    text-align: center;
  }

  .footer-links-column h3 {
    font-size: 1.2rem;
  }

  .footer-links-column ul {
    padding: 0;
    margin: 0;
  }

  .footer-links-column li {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo-img {
    margin-bottom: 10px;
  }

  .footer-bottom-right p {
    font-size: 12px;
    margin: 10px 0;
  }
}

