
/* General Styles */
.who-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 20px 0px;
}

.who-filled-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #356745; /* Replace with the desired background color */
  transition: all 0.3s ease-in-out;
  opacity: 0; /* Initially hidden */
  z-index: -1; /* To make sure it's behind the content */
}

.who-section-in-view {
  opacity: 1;
  width: 100%;
  height: 100%;
}


.who-content {
    width: 100%;
    background-color: transparent;
    display: flex;
}

.who-header-width{
  width: 50%;
  position: relative;
}
.who-header-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 40px 20px;
    text-align: center;
    width: 100%;
}

.who-about-us {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 150px;
    box-shadow: 0px 9px 15px rgba(85, 109, 145, 0.08);
    border-radius: 65px;
    padding: 4px 17px;
    gap: 10px;
    margin-bottom: 10px;
}

.who-gradient-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: linear-gradient(207deg, #00c8ff 23%, #a90c0c 82%);
}

.who-about-us-text {
    color: #52525C;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 25px;
}

.who-heading .who-main-text {
    color: white;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 40px;
    text-transform: none;
}

.who-heading .who-sub-text {
    color: white;
    text-transform: none;
    font-size: 34px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    line-height: 40px;
}

.who-info-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
    gap: 20px; /* Space between the cards */
    margin: 10px 20px;
    position: relative;
    justify-content: center;
    width: 43%;
    padding: 20px;
    z-index: 1;
}

.who-card:hover {
    background: #ffffff;
    color: rgb(0, 0, 0);
}

.who-card {
    background: #119637;
    padding: 20px;
    color: white;
    border-radius: 0px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Ensure positioning works */
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}

/* General card styles */
.who-card-design {
    position: absolute;
    bottom: -50px; /* Adjust spacing as needed */
    right: -50px;
    width: 130px; /* Width of the container (circular shape) */
    height: 130px; /* Height of the container (circular shape) */
    padding: 20px; /* Spacing inside the element */
    background: #09E436; /* Optional background color */
    border-radius: 50%; /* Makes it circular */
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

/* Specific background images for each card */
.who-we-are-design {
    background-image: url('../../asserts/images/who-we-wre2.svg');

}

.who-values-design {
    background-image: url('../../asserts/images/Our-values.svg');

}

.who-mission-design {
    background-image: url('../../asserts/images/Our-mission.svg');

}

.who-why-choose-us-design {
    background-image: url('../../asserts/images/why-choose-us.svg');

}
.who-we-are-design,
    .who-values-design,
    .who-mission-design,
    .who-why-choose-us-design{
        background-size: 45px 35px; 
        background-position: 21px 28px;
    }

.who-card-header {
    font-size: 19px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
}

.who-card-body {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.9;
    margin-bottom: 30px;
}

.who-learn-more {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: white;
    margin-top: auto;
    margin-bottom: 20px;
}




@media (max-width: 1024px) {
    .who-heading .who-main-text {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
    }
    .who-heading .who-sub-text {
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
    }
    .who-gradient-circle {
        width: 6px;
        height: 6px;
    }
    
    .who-about-us {
        width: 140px;
        padding: 5px 27px;
        gap: 10px;
    }

    .who-about-us-text {
        font-size: 11px;
        font-weight: 700;
        line-height: 19px;
    }
    .who-card-header {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    
    .who-card-body {
        font-size: 10px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 30px;
    }
    
    .who-learn-more {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .who-card-design {
        bottom: -40px; /* Adjust spacing as needed */
        right: -40px;
        width: 90px; /* Width of the container (circular shape) */
        height: 90px; /* Height of the container (circular shape) */
        padding: 20px; /* Spacing inside the element */
    }
    .who-we-are-design,
    .who-values-design,
    .who-mission-design,
    .who-why-choose-us-design{
        background-size: 30px 20px; 
        background-position: 13px 20px;
    }
}
/* Responsive Adjustments */
@media (max-width: 768px) {
    .who-header-section {
        padding: 30px 10px;
    }
    .who-info-section {
        grid-template-columns: repeat(2, 1fr);
        margin: 4px 0px;
        width: 50%;
        gap: 10px;
    }
    

    .who-card {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .who-heading .who-main-text {
        font-size: 24px;
    }
    .who-info-section {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Creates 2 columns */
    }

    .who-heading .who-sub-text {
        font-size: 18px;
    }
}
