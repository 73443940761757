.case-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center;
    text-align: center; /* Center text inside elements */
    padding: 50px 0px 0px 0px; /* Remove any default margin */
  }
  
  .case-study {
    padding: 8px 15px;
    background: white;
    box-shadow: 0px 5px 15px rgba(87, 119, 88, 0.177);
    border-radius: 45px;    
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    max-width: 50%;
    margin-bottom: 10px;
  }
  
  .case-study-text {
    padding: 5px 30px;
    font-size: 1rem;
    margin: 0;
  }
  
  .case-heading {
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }
  
  @media (max-width: 1024px) {
    .case-heading {
      font-size: 1.5rem;
    }
    .case-study-text {
      padding: 0 10px;
      font-size: 0.7rem;
      margin: 0;
    }
  }