/* Include slick-carousel styles in your main CSS or App.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel-container {
    overflow: hidden; /* Ensures smooth horizontal scrolling */
    padding: 20px 0;
    max-width: 100%;
    margin-bottom: 50px;

}
.carousel-card {
    background: #F8F8F8; /* Light gray background */
    box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.20); /* Soft shadow */
    border-radius: 0px; /* Optional: adds rounded corners */
    padding: 20px;
    text-align: left;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px; /* Maximum width */
    box-sizing: border-box;
    position: relative;
    overflow: hidden; /* Prevents content from overflowing */
    z-index: 1; /* Keep it above the next card */
}

/* Optional: Add shadow below the card for a "cut-out" effect */
.carousel-card::after {
    content: '';
    position: absolute;
    bottom: -20px; /* Pushes the shadow or cut-out effect below the card */
    left: 0;
    right: 0;
    height: 20px; /* Size of the cut-out space */
    background: #F8F8F8; /* Same background color */
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1); /* Soft shadow beneath the card */
    z-index: -1; /* Makes sure the cut-out doesn’t overlap the card content */
}

.carousel-card {
    background:#ffffff; /* Default light gray background */
    transition: background 0.3s ease-in-out, transform 0.2s ease; /* Smooth transition for hover effect */
}

.carousel-card:hover {
    background: #00D444; /* White background on hover */
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25); /* Enhanced shadow for hover effect */
}


/* Slick-specific styling */
.slick-track {
    display: flex !important;
    gap: 20px; /* Space between slides */
}

.card-header {
    display: flex;
    align-items: center;
    gap: 20px;
    
}

.card-logo {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}
.carousel-card:hover .card-title,
.carousel-card:hover .card-content p {
    color: #ffffff;
}
.card-title {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #141517;
}
.card-content{
    margin-top: 30px;
}
.card-content p {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    line-height: 19px;
    color: #2f2f2f;

}

.card-content strong {
    font-weight: 700;
}

/* Slick-specific styling */
.slick-slide {
    padding: 0 10px; /* Padding to control slide spacing */
}

.slick-list {
    margin: 0 -10px; /* Prevent unwanted gaps */
}

/* Media Queries for Responsiveness */

/* For tablets and small screens */
@media (max-width: 1024px) {

    .slick-track {
        gap: 15px; /* Reduce space between slides */
    }
    .slick-slide {
        padding: 0 5px;
    }
    .carousel-card {
        width: 250px;
    }
}

/* For mobile screens */
@media (max-width: 768px) {
    .carousel-card {
        width: 200px; /* Further reduce card width */
    }
    .slick-track {
        gap: 10px; /* Further reduce space between slides */
    }
    .slick-slide {
        padding: 0 5px;
    }
}

/* For very small screens (e.g., mobile landscape or small mobile devices) */
@media (max-width: 480px) {
    .carousel-card {
        max-width: 270px; /* Adjust to fit small screens */
    }
    .slick-track {
        gap: 5px; /* Further reduce space */
    }
    .slick-slide {
        padding: 0 5px;
    }
}
